import { HttpStatus } from '../enums/http-status.enum'
import { HttpException } from './http'

class InternalServerErrorException extends HttpException {
  constructor(stringOrError?: string | Error) {
    super(HttpException.createBody(HttpStatus.INTERNAL_SERVER_ERROR, stringOrError), HttpStatus.INTERNAL_SERVER_ERROR)
  }
}

export default InternalServerErrorException
