'use client'

import { ChangeEvent, FC, useState } from 'react'
import cx from 'clsx'
import { useParams, useRouter } from 'next/navigation'
import { MagnifyingGlass } from 'phosphor-react-sc'
import { keywordHandler } from '@/data/process-jobs-search-data'
import { ButtonLink, ImageAsset, Section } from '@/types/site.interface'
import Loading from '@ignition/library/components/atoms/loading'
import useNextLoading from '@ignition/library/hooks/use-next-loading'
import { Button } from '../../atoms/button'
import Input from '../../molecules/form/elements/input'
import SectionHeading from '../../molecules/section/section-heading'
import ImageElement, { aspectRatio } from '../image'
import { Keyword } from '../job-listing/interface'
import { LocationSelector } from './location-selector'

interface Background {
  backgroundColor?: string
  image?: ImageAsset
}

interface JobSearchProps {
  section: Section
  button: ButtonLink
  imageEnable: boolean
  lightTheme: boolean
  background?: Background
}

const styles = {
  section: 'mx-auto sm:grid justify-items-center relative px-4 py-14 md:px-14',
  parent: 'w-full max-w-none xl:max-w-2xl relative mx-auto mt-auto',
  heading: (lightTheme: boolean) =>
    cx(
      'prose-headings:text-left prose-p:text-left  prose-p:max-w-none max-w-xl',
      lightTheme ? 'drop-shadow-depth prose-headings:text-white prose-p:text-gray-100' : 'prose-p:text-black ',
    ),
  form: 'flex flex-col sm:flex-row items-center sm:bg-gray-100 gap-2 sm:gap-0 sm:rounded-full overflow-visible py-0 pr-2 justify-between',
  inputDiv: 'relative w-full sm:w-3/6 ',
  inputSearch:
    'text-lg placeholder:text-lg rounded-full placeholder:text-gray-300 border-0 w-full py-4 pl-12 text-gray-700 bg-gray-100 leading-tight focus:outline-none focus:border-gray-100 focus:border-0',
  searchIcon: 'w-6 h-6 absolute left-4 top-4 text-gray-300',
  divider: 'text-gray-300 max-sm:hidden',
  buttonDiv: 'rounded-full bg-gray-100',
  button: 'rounded-full md:px-20 py-2 whitespace-nowrap',
}

const searchPath = (obj) => {
  const keys: string[] = []
  Object.keys(obj).forEach((key) => {
    if (obj[key] && key !== 'page') {
      keys.push(`${key}/${obj[key]}`)
    }
  })
  if (Object.keys(obj).includes('location') && !Object.keys(obj).includes('radius')) {
    keys.push('radius/50')
  }
  return keys.join('/')
}

export const JobSearch: FC<JobSearchProps> = ({ section, button, background = {}, imageEnable, lightTheme }) => {
  const { backgroundColor, image } = background
  const { push } = useRouter()
  const params = useParams()
  const { slug } = params
  const initialKeyword = slug ? keywordHandler(typeof slug === 'string' ? [slug] : slug) : {}
  const isLoading = useNextLoading()
  const [keyword, setKeyword] = useState<Keyword>(initialKeyword)

  const pushRouter = (keyword: Keyword) => {
    push(`/jobs/${searchPath(keyword)}`)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    pushRouter(keyword)
  }

  const onChangeHandler = (value: string, name: string) => {
    setKeyword((prev) => ({ ...prev, [name]: value }))

    // automatic updates if one of the coming name keywords is empty
    if (name === 'location' && keyword.location && !value) {
      pushRouter({ ...keyword, location: undefined, radius: undefined })
    }

    if (name === 'search' && keyword.search && !value) {
      pushRouter({ ...keyword, search: undefined })
    }
  }

  return (
    <section
      className={cx('c-job-search', styles.section, aspectRatio(image?.customRatio))}
      style={{ backgroundColor }}
    >
      {imageEnable && <ImageElement image={image} priority={true} fill />}
      <div className={styles.parent}>
        <div className={styles.heading(lightTheme)}>
          <SectionHeading {...section} />
        </div>
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.inputDiv}>
            <Input
              name="search"
              type="search"
              onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeHandler(e.target.value, e.target.name)}
              value={keyword.search ? decodeURIComponent(keyword.search) : ''}
              className={styles.inputSearch}
              placeholder="Job Titel ..."
              style={{ boxShadow: 'none' }}
            />
            <MagnifyingGlass className={styles.searchIcon} weight="bold" />
          </div>
          <div className={styles.divider}>|</div>
          <LocationSelector keyword={keyword} setKeyword={onChangeHandler} />
          <div className="relative">
            {button?.title && (
              <div className="cursor-pointer">
                <Button type="button" submit variant={button?.variant} className={styles.button}>
                  {!isLoading ? (
                    button?.title
                  ) : (
                    <div className="p-1 py-2">
                      <Loading color="white" size="sm" />
                    </div>
                  )}
                </Button>
              </div>
            )}
          </div>
        </form>
      </div>
    </section>
  )
}
