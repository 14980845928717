'use client'

import { FC } from 'react'
import { useCarouselContext } from '../constants'

interface CarouselNextButtonProps {
  onClick: () => void
  disabled: boolean
}

interface NextButtonProps {
  children: (props: CarouselNextButtonProps) => JSX.Element
}

export const NextButton: FC<NextButtonProps> = ({ children }) => {
  const { scrollNext, canScrollNext, scrollSnaps } = useCarouselContext()
  if (scrollSnaps.length <= 1) {
    return null
  }

  return children({ onClick: () => scrollNext(), disabled: !canScrollNext })
}
