'use client'

import { FC } from 'react'
import clsx from 'clsx'
import { useEmblaCarouselMultiple } from '../constants'

interface SlideListProps {
  slides: JSX.Element[]
  className?: string
  renderSlide?: (slide: JSX.Element, index: number) => JSX.Element
  containerClassName?: string
  hideOverflow?: boolean
}

export const SlideList: FC<SlideListProps> = ({
  slides,
  className,
  renderSlide = (slide, i) => (
    <div key={i} className="min-w-full min-h-full">
      {slide}
    </div>
  ),
  containerClassName,
  hideOverflow = true,
}) => {
  const viewportRef = useEmblaCarouselMultiple()
  const itemsList = slides.map((slide, i) => renderSlide(slide, i))

  return (
    <div className={clsx('w-full', hideOverflow && 'overflow-hidden', className)} ref={viewportRef}>
      <div className={clsx('flex min-w-full min-h-full', containerClassName)}>{itemsList}</div>
    </div>
  )
}
