'use client'
import { FC, useState } from 'react'
import ReactPlayer, { ReactPlayerProps } from 'react-player/lazy'
import cx from 'clsx'
import { Play, X } from 'phosphor-react-sc'
import { ImageAsset } from '@/types/site.interface'
import ImageElement from '../image'

const styles = {
  posterParent:
    'w-full relative max-w-[100%] aspect-[16/9] mx-auto overflow-hidden rounded-2xl md:rounded-element-bg cursor-pointer',
  defaultPoster: 'w-full h-full absolute inset-0 bg-primary',
  playButton:
    'absolute md:w-24 md:h-24 bg-primary flex place-content-center items-center top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-gray-300 rounded-full p-4 md:p-7',
  closeButton: 'absolute top-4 right-4 md:top-8 md:right-8 lg:top-4 text-white text-2xl',
  videoLightBox: 'fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-2 md:p-6 lg:p-12',
}

interface YoutubeProps extends ReactPlayerProps {
  videoUrl: string
  title: string
  posterImage?: ImageAsset
  posterClassName?: string
}

export const Youtube: FC<YoutubeProps> = ({ videoUrl, title, posterImage, posterClassName }) => {
  const [isPlaying, setIsPlaying] = useState(false)

  const playVideo = () => {
    setIsPlaying(true)
  }

  const closeVideo = () => {
    setIsPlaying(false)
  }

  return (
    <div className="relative">
      <div className={cx(posterClassName ? posterClassName : styles.posterParent)} onClick={playVideo}>
        {posterImage?.asset ? (
          <ImageElement image={posterImage} alt={title} fill />
        ) : (
          <div className={styles.defaultPoster} />
        )}
      </div>
      {!isPlaying ? (
        <button className={styles.playButton} onClick={playVideo}>
          <Play weight="fill" className="text-white w-6 h-6 md:w-28 md:h-28" />
        </button>
      ) : (
        <div className={styles.videoLightBox}>
          <button className={styles.closeButton} onClick={closeVideo}>
            <X size={32} weight="bold" />
          </button>
          <ReactPlayer
            className="w-full h-full"
            url={videoUrl}
            title={title}
            playing={isPlaying}
            controls
            width="90%"
            height="90%"
            config={{
              youtube: {
                playerVars: {
                  autoplay: 1,
                  mute: 0,
                },
              },
            }}
            onEnded={closeVideo}
          />
        </div>
      )}
    </div>
  )
}
