'use client'
import { FC } from 'react'
import { CorporateContactJsonLd } from 'next-seo'
import { onSubmit } from '@/components/elements/dynamic-form/form-submission'
import Form from '@/components/molecules/form/form'
import { Block, Section as ISection } from '@/types/site.interface'
import Grid from '@ignition/library/components/atoms/grid'
import BlockContent from '../../blocks/block-content'
import Section from '../../molecules/section'
import { CompanyContacts } from './company-contacts'
import EmbedForm from './embed-form'
import { FormsProps, LocationProps } from './interface'

type FormType = 'embedForm' | 'dynamicForm'

interface DynamicFormProps {
  form?: FormsProps
  location: LocationProps
  section: ISection
  privacyHref?: string
  formMessage: Block
  contactJsonLd: ContactJsonLdProps
  formType: FormType
}

const FormProvider = (formType: FormType, privacyHref?: string, form?: FormsProps) => {
  switch (formType) {
    case 'embedForm':
      return <EmbedForm />
    case 'dynamicForm':
      return form ? <Form id={form._id} privacyHref={privacyHref} onSubmit={onSubmit} formItems={form.form} /> : null
    default:
      return null
  }
}

const DynamicForm: FC<DynamicFormProps> = ({
  contactJsonLd,
  section,
  form,
  location,
  formMessage,
  privacyHref,
  formType,
}) => {
  const formProvider = FormProvider(formType, privacyHref, form)

  return (
    <Section section={section} className="c-dynamic-form">
      <ContactJsonLd {...contactJsonLd} />
      <Grid columns={{ base: 1, md: 2, lg: 5 }} gap={{ base: 12, sm: 4 }}>
        <Grid.Item className="text-left text-gray-500" colspan={{ base: 1, lg: 2 }}>
          <BlockContent
            blocks={formMessage}
            className="prose-headings:text-3xl text-lg leading-6 prose-headings:font-bold prose-headings:font-heading prose-headings:heading-style prose-headings:text-heading"
          />
          <CompanyContacts email={location?.email} phone={location?.phone} />
        </Grid.Item>
        <Grid.Item colspan={{ base: 1, lg: 3 }}>{formProvider}</Grid.Item>
      </Grid>
    </Section>
  )
}

interface General {
  logo: string
  url: string
}

interface ContactPoint {
  areaServed: string[]
  availableLanguage: string[]
  contactType: string
  email: string
  telephone: string
}

export interface ContactJsonLdProps {
  general: General
  contactPoint: ContactPoint
}

export const ContactJsonLd: FC<ContactJsonLdProps> = ({ contactPoint, general }) => {
  return <CorporateContactJsonLd useAppDir {...general} contactPoint={[contactPoint]} />
}

export default DynamicForm
