import { FC } from 'react'
import { Envelope, Phone } from 'phosphor-react-sc'

interface CompanyContactsProps {
  email?: string
  phone?: string
}

export const CompanyContacts: FC<CompanyContactsProps> = ({ email, phone }) => {
  if (!email && !phone) {
    return null
  }

  return (
    <dl className="mt-8 text-base">
      {phone && (
        <div className="mb-3">
          <dt className="sr-only">Telefon</dt>
          <dd className="flex">
            <Phone className="h-6 w-6 flex-shrink-0 text-gray-400" aria-hidden="true" />
            <span className="ml-3">{phone}</span>
          </dd>
        </div>
      )}
      {email && (
        <div>
          <dt className="sr-only">Email</dt>
          <dd className="flex">
            <Envelope className="h-6 w-6 flex-shrink-0 text-gray-400" aria-hidden="true" />
            <span className="ml-3">{email}</span>
          </dd>
        </div>
      )}
    </dl>
  )
}
