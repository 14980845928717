'use client'
import { Fragment, useEffect, useState } from 'react'

const styleHtml = `
  html,body{
      margin: 0px;
    }
    #crmWebToEntityForm.zcwf_lblRight {
      width:100%;
      padding: 25px;
      margin: 0 auto;
      box-sizing: border-box;
    }
    #crmWebToEntityForm.zcwf_lblRight * {
      box-sizing: border-box;
    }
    #crmWebToEntityForm{text-align: left;}
    #crmWebToEntityForm * {
      direction: ltr;
    }
    .zcwf_lblRight .zcwf_title {
      word-wrap: break-word;
      font-weight:bold }.zcwf_lblRight.cpT_primaryBtn:hover{
      background: linear-gradient(#02acff 0,#006be4 100%) no-repeat padding-box !important;
      box-shadow: 0 -2px 0 0 #0159b9 inset !important;
      border: 0 !important;
      color: #fff !important;
      outline: 0 !important;
    }.zcwf_lblRight .zcwf_col_fld input[type=text], input[type=password], .zcwf_lblRight .zcwf_col_fld textarea {
      width: 100%;
      border: 1px solid #c0c6cc !important;
      resize: vertical;
      border-radius: 2px;
      float: left;
    }
    .zcwf_lblRight .zcwf_col_lab {
      width: auto;
      word-break: break-word;
      padding: 0px 6px 0px;
      font-weight: 500;
      margin-right: 10px;
      margin-top: 5px;
      float: left;
      min-height: 1px;
      text-align: left;
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
    .zcwf_lblRight .zcwf_col_fld {
      float: left;
      width: 100%;
      padding: 0px 6px 0px;
      position: relative;
      margin-top: 5px;
    }
    .zcwf_lblRight .zcwf_privacy{padding: 6px;}
    .zcwf_lblRight .wfrm_fld_dpNn{display: none;}
    .dIB{display: inline-block;}
    .zcwf_lblRight .zcwf_col_fld_slt {
      width: 100%;
      border: 1px solid #ccc;
      background: #fff;
      border-radius: 4px;
      font-size: 14px;
      float: left;
      resize: vertical;
      padding: 2px 5px;
    }
    .zcwf_lblRight .zcwf_row:after, .zcwf_lblRight .zcwf_col_fld:after {
      content: '';
      display: table;
      clear: both;
    }
    .zcwf_lblRight .zcwf_col_help {
      float: left;
      margin-left: 7px;
      font-size: 14px;
      max-width: 35%;
      word-break: break-word;
    }
    .zcwf_lblRight .zcwf_help_icon {
      cursor: pointer;
      width: 16px;
      height: 16px;
      display: inline-block;
      background: #fff;
      border: 1px solid #c0c6cc;
      color: #c1c1c1;
      text-align: center;
      font-size: 11px;
      line-height: 16px;
      font-weight: bold;
      border-radius: 50%;
    }
    .zcwf_lblRight .zcwf_row {margin: 15px 0px;}
    .zcwf_lblRight .formsubmit {
      margin-right: 5px;
      cursor: pointer;
      color: var(--baseColor);
      font-size: 14px;
    }
    .zcwf_lblRight .zcwf_privacy_txt {
      width: 90%;
      color: rgb(0, 0, 0);
      font-size: 14px;
      
      display: inline-block;
      vertical-align: top;
      color: var(--color-text);
      padding-top: 2px;
      margin-left: 6px;
    }
    .zcwf_lblRight .zcwf_button {
      font-size: 14px;
      color: var(--color-text);
      border: 1px solid #c0c6cc;
      padding: 3px 9px;
      border-radius: 4px;
      cursor: pointer;
      max-width: 120px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .zcwf_lblRight .zcwf_tooltip_over{
      position: relative;
    }
    .zcwf_lblRight .zcwf_tooltip_ctn{
      position: absolute;
      background: #dedede;
      padding: 3px 6px;
      top: 3px;
      border-radius: 4px;word-break: break-word;
      min-width: 100px;
      max-width: 150px;
      color: var(--baseColor);
      z-index: 100;
    }
    .zcwf_lblRight .zcwf_ckbox{
      float: left;
    }
    .zcwf_lblRight .zcwf_file{
      width: 55%;
      box-sizing: border-box;
      float: left;
    }
    .clearB:after{
      content:'';
      display: block;
      clear: both;
    }
`

const valueRegex = /^\s+|\s+$/g

const formHandlers = `
  function validateEmail662994000000457013()
	{
		var form = document.forms['WebToLeads662994000000457013'];
		var emailFld = form.querySelectorAll('[ftype=email]');
		var i;
		for (i = 0; i < emailFld.length; i++)
		{
			var emailVal = emailFld[i].value;
			if((emailVal.replace(${valueRegex}, '')).length!=0 )
			{
				var atpos=emailVal.indexOf('@');
				var dotpos=emailVal.lastIndexOf('.');
				if (atpos<1 || dotpos<atpos+2 || dotpos+2>=emailVal.length)
				{
					alert('Bitte geben Sie eine gültige E-Mail-Adresse ein. ');
					emailFld[i].focus();
					return false;
				}
			}
		}
		return true;
	}

 	  function checkMandatory662994000000457013() {
		var mndFileds = new Array('Company','First Name','Last Name','Email');
		var fldLangVal = new Array('Unternehmen','Vorname','Nachname','E\x2DMail');
		for(i=0;i<mndFileds.length;i++) {
        var fieldObj=document.forms['WebToLeads662994000000457013'][mndFileds[i]];
        if(fieldObj) {
        if (((fieldObj.value).replace(${valueRegex}, '')).length==0) {
        if(fieldObj.type =='file')
          { 
          alert('Bitte wählen Sie eine Datei zum Hochladen.'); 
          fieldObj.focus(); 
          return false;
          } 
        alert(fldLangVal[i] +' darf nicht leer sein.'); 
                fieldObj.focus();
                return false;
        }  else if(fieldObj.nodeName=='SELECT') {
              if(fieldObj.options[fieldObj.selectedIndex].value=='-None-') {
          alert(fldLangVal[i] +' darf nicht leer sein.'); 
          fieldObj.focus();
          return false;
          }
        } else if(fieldObj.type =='checkbox'){
        if(fieldObj.checked == false){
          alert('Please accept  '+fldLangVal[i]);
          fieldObj.focus();
          return false;
          } 
        } 
        try {
            if(fieldObj.name == 'Last Name') {
          name = fieldObj.value;
            }
        } catch (e) {}
          }
      }
      if(!validateEmail662994000000457013()){return false;}
      document.querySelector('.crmWebToEntityForm .formsubmit').setAttribute('disabled', true);
    }

    function tooltipShow662994000000457013(el){
      var tooltip = el.nextElementSibling;
      var tooltipDisplay = tooltip.style.display;
      if(tooltipDisplay == 'none'){
        var allTooltip = document.getElementsByClassName('zcwf_tooltip_over');
        for(i=0; i<allTooltip.length; i++){
          allTooltip[i].style.display='none';
        }
        tooltip.style.display = 'block';
      }else{
        tooltip.style.display='none';
      }
    }
`

const form = `
  <meta name='viewport' content='width=device-width, initial-scale=1.0'>
  <META HTTP-EQUIV='content-type' CONTENT='text/html;charset=UTF-8'>
  <form id='webform' action='https://crm.zoho.eu/crm/WebToLeadForm' name=WebToLeads662994000000457013 method='POST' onSubmit='javascript:document.charset="UTF-8"; return checkMandatory662994000000457013()' accept-charset='UTF-8'>
    <input type='text' style='display:none;' name='xnQsjsdp' value='ca30af18d315f20e2e754d93c0cb5b0eaf960fac356465605f8d6e884e2b7609'></input> 
    <input type='hidden' name='zc_gad' id='zc_gad' value=''></input>
    <input type='text' style='display:none;' name='xmIwtLD' value='ec5c519202523f7ef4bbcd856b4eb8232ce9e044e8e1c51cbd93cdf5f069d24faff51fc4a0860b8a935d75769dd501f3'></input> 
    <input type='text'  style='display:none;' name='actionType' value='TGVhZHM='></input>
    <input type='text' style='display:none;' name='returnURL' value='https&#x3a;&#x2f;&#x2f;jobs.recrewted.de&#x2f;business'> </input>
    <div class='zcwf_title' style='max-width: 600px;color: #666666;'>Kontaktieren Sie uns noch heute!</div>
    
    <div class='zcwf_row'>
      <div class='zcwf_col_lab' style='font-size:14px;'>
        <label for='First_Name'>Vorname<span style='color:red;'>*</span></label>
      </div>
      <div class='zcwf_col_fld'>
        <input type='text' id='First_Name' name='First Name' maxlength='40'></input>
        <div class='zcwf_col_help'></div>
      </div>
    </div>
      
    <div class='zcwf_row'>
      <div class='zcwf_col_lab' style='font-size:14px;'>
        <label for='Last_Name'>Nachname<span style='color:red;'>*</span></label>
      </div>
      <div class='zcwf_col_fld'>
        <input type='text' id='Last_Name' name='Last Name' maxlength='80'></input>
        <div class='zcwf_col_help'></div>
      </div>
    </div>

    <div class='zcwf_row'>
      <div class='zcwf_col_lab' style='font-size:14px;'>
        <label for='Company'>Unternehmen<span style='color:red;'>*</span></label>
      </div>
      <div class='zcwf_col_fld'>
        <input type='text' id='Company' name='Company' maxlength='200'></input>
        <div class='zcwf_col_help'></div>
      </div>
    </div>

    <div class='zcwf_row'>
      <div class='zcwf_col_lab' style='font-size:14px;'>
        <label for='Email'>E-Mail<span style='color:red;'>*</span></label>
      </div>
      <div class='zcwf_col_fld'>
        <input type='text' ftype='email' autocomplete='false' id='Email' name='Email' maxlength='100'></input>
        <div class='zcwf_col_help'></div>
      </div>
    </div>

    <div class='zcwf_row'>
      <div class='zcwf_col_lab' style='font-size:14px;'>
        <label for='Phone'>Tel.</label>
      </div>
      <div class='zcwf_col_fld'>
        <input type='text' id='Phone' name='Phone' maxlength='30'></input>
        <div class='zcwf_col_help'></div>
      </div>
    </div>

    <div class='zcwf_row'>
      <div class='zcwf_col_lab' style='font-size:14px;'>
        <label for='Description'>Nachricht</label>
      </div>
      <div class='zcwf_col_fld'>
        <textarea id='Description' name='Description'></textarea>
        <div class='zcwf_col_help'></div>
      </div>
    </div>

    <div class='zcwf_row'>
      <div class='zcwf_col_lab'></div>
      <div class='zcwf_col_fld'>
        <input type='submit' id='formsubmit' class='formsubmit zcwf_button' value='Senden' title='Senden'></input>
        <input type='reset' class='zcwf_button' name='reset' value='Zur&uuml;cksetzen' title='Zur&uuml;cksetzen'></input>
      </div>
    </div>

  </form>
`

const EmbedForm = () => {
  const [mount, setMount] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setMount(true)
    }, 500)
  }, [])
  return (
    <Fragment>
      <style dangerouslySetInnerHTML={{ __html: styleHtml }} />
      <script dangerouslySetInnerHTML={{ __html: formHandlers }} />
      {mount && (
        <div
          id="crmWebToEntityForm"
          className="zcwf_lblRight crmWebToEntityForm bg-background text-text max-w-full"
          dangerouslySetInnerHTML={{ __html: form }}
        />
      )}
      <script
        id="wf_anal"
        src="https://crm.zohopublic.eu/crm/WebFormAnalyticsServeServlet?rid=ff50446252e6fe2773fcf0034b33ad01fffa8a1367cf930e69d97502f2b94914dbc3039b87d28098272881b05ef5897fgid38919a54249c28a73d80da2fd190fcfa53dbc7f8882fc4cf6428f2a91e43c077gid98c4af04b46fa76edc7b8e8106ec2866a9aa8b672a5755355820e8432b3ef919gid10b6c7eeeaa6d45098948db8f70e6544cd61ec76ee7adf471b4ec478122461a7&tw=d037091954f68bef7670616a4ed8aaedfe1434efbd7ccc63b91d6e0267883e34"
      />
    </Fragment>
  )
}

export default EmbedForm
