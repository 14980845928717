import qs from 'querystringify'
import { Fetcher } from 'swr'
import { getConfigPublicValue } from '@/config'
import InternalServerErrorException from '@ignition/library/lib/exceptions/internal-server'
import logger from '@ignition/library/lib/logger'
import { isString } from '@ignition/library/utils/string'

interface Query {
  [key: string]: string | number
}

const limit = 9
const API_SEARCH_URL = `${getConfigPublicValue('services.recrewted.api')}/api/v2`

export const fetcher = async (endpoint: string, query?: Query) => {
  try {
    const response = await fetch(`${API_SEARCH_URL}/${endpoint}?${qs.stringify(query)}`, { method: 'GET' }).then(
      (res) => res.json(),
    )
    if (response?.statusCode !== 200) {
      logger.error(response?.error)
      return new InternalServerErrorException(response.error)
    }
    return response
  } catch (error) {
    logger.error(error)
    new InternalServerErrorException(error)
  }
}

export const fetchSearchJobs = async (query: Query) => {
  const { search, page, location, radius, category, company, filter } = query
  const currentPage = page !== '0' ? parseInt(page as string) + 1 : 1

  const notSearchTerm = !search && !location && !radius && !filter
  const useEndpoint = notSearchTerm ? 'get-jobs' : 'search-jobs'
  const searchParams = {
    limit,
    page: currentPage,
  }
  if (search) searchParams['title'] = search
  if (location) searchParams['locationslug'] = location
  if (radius) searchParams['radius'] = radius
  if (category) searchParams['category'] = category
  if (company) searchParams['company'] = company
  if (filter) searchParams['tag'] = decodeURIComponent(filter as string)
  const response = await fetcher(`/jobs/${useEndpoint}`, searchParams)

  return { data: response?.data ?? [], metaData: response?.metaData?.pagination }
}

export const fetchGetJob = async (id: string) => {
  const response = await fetcher(`/jobs/get-job`, { id })
  return response?.data ?? {}
}

export const fetchHint: Fetcher<never[], string> = async (endpoint: string) => {
  if (!isString(endpoint)) return []
  const res = await fetch(`${API_SEARCH_URL}/locations${endpoint}`)
  const data = await res.json()

  if (!res.ok || data?.error) {
    logger.error(data?.error)
  }

  return data?.data
}
