'use client'

import { ChangeEvent, FC, Fragment, useState } from 'react'
import cx from 'clsx'
import { CaretDown, MapPinLine } from 'phosphor-react-sc'
import useSWR from 'swr'
import Input from '@/components/molecules/form/elements/input'
import { fetchHint } from '@/data/fetch-jobs-search'
import { PropsData } from '@/types/site.interface'
import { Menu, Transition } from '@headlessui/react'
import { Keyword } from '../job-listing/interface'

interface Option {
  name: string
  slug?: string
}

const kilometers = [
  { id: '10', text: '10 km' },
  { id: '25', text: '25 km' },
  { id: '50', text: '50 km' },
  { id: '100', text: '100 km' },
  { id: '200', text: '200 km' },
]

const selectorStyles = {
  parent: 'relative w-full sm:w-3/6 overflow-visible',
  inputDiv:
    'relative w-full cursor-default overflow-hidden rounded-full bg-gray-100 text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-primary sm:text-sm',
  pinMapIcon: 'w-7 h-7 absolute left-4 top-3 text-gray-300',
  input:
    'w-full text-lg placeholder:text-gray-300 border-none py-4 pl-12 px-10 bg-gray-100 leading-5 text-gray-900 focus:ring-0 shadow-none',
  button: 'absolute inset-y-0 top-1 right-2 flex items-center pr-2',
  caretDownIcon: (disabled: boolean) => cx('right-0 self-center h-6 w-6', disabled ? 'text-gray-300' : 'text-gray-600'),
  optionsBox:
    'relative w-auto z-[1000] rounded-md bg-gray-100  text-base shadow-lg ring-black ring-opacity-5 focus:outline-none sm:text-base',
  nothingFound: 'relative cursor-default select-none py-2 px-4 text-gray-700',
  activeOption: (active: boolean) =>
    cx('relative cursor-default select-none py-2 pl-10 pr-4', active ? 'bg-primary text-white' : 'text-gray-900'),
  selected: (active: boolean) =>
    cx('absolute inset-y-0 left-0 flex items-center pl-3', active ? 'text-white' : 'text-primary'),
  radiusSelectorButton: (disabled: boolean) =>
    cx(
      'inline-flex w-full justify-center rounded-md pl-2 lg:text-lg hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75',
      disabled ? 'text-gray-300' : 'text-black',
    ),
}

const unslugifyLocation = (text: string) => {
  const [town, state] = text.split('_at_').map((t) => decodeURI(t))
  return `${town}, ${state}`
}

const RadiusSelector = ({ radius, setRadius, disabled = false }) => {
  return (
    <Menu as="div" className={'overflow-hidden bg-gray-100 rounded-lg z-10'}>
      <div>
        <Menu.Button disabled={disabled} className={selectorStyles.radiusSelectorButton(disabled)}>
          {!radius ? 'Distanz' : `${radius} km`}
          <CaretDown className={selectorStyles.caretDownIcon(disabled)} weight="bold" aria-hidden="true" />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 mt-2 divide-y  divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="px-1 py-1 ">
            {kilometers.map((km) => (
              <Menu.Item key={km.id}>
                {({ active }) => (
                  <span
                    onClick={() => setRadius(km.id)}
                    className={cx(
                      active ? 'bg-primary text-white' : 'text-gray-900',
                      'group flex items-center rounded-md py-2 justify-center w-20 lg:text-base',
                    )}
                  >
                    {km.text}
                  </span>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

interface SelectorTableProps {
  location: Option[]
  setLocation: (opt: Option) => void
}

const SelectorTableProps = ({ location, setLocation }: SelectorTableProps) => {
  const onClickHandler = ({ name, slug }: Option) => {
    setLocation({ name, slug })
  }
  return location.length > 0 ? (
    <div className="z-10 md:z-0 absolute w-full flex-1 mt-1 overlay rounded-lg overflow-visible border bg-white">
      {location.map((loc) => {
        return (
          <div key={loc.slug} onClick={() => onClickHandler(loc)}>
            <p className="cursor-pointer w-full text-base py-3 border-gray-100 px-3 bg-transparent text-gray-500 shadow-sm hover:bg-primary hover:text-white">
              {loc.name}
            </p>
            <span className={'border-b border-gray-200 '}></span>
          </div>
        )
      })}
    </div>
  ) : null
}

interface LocationSelectorProps {
  keyword: Keyword
  setKeyword: (value: string, name: string) => void
}

export const LocationSelector: FC<LocationSelectorProps> = ({ keyword, setKeyword }) => {
  const [location, setLocation] = useState<Option>(
    keyword?.location ? { name: unslugifyLocation(keyword.location), slug: keyword.location } : { name: '' },
  )
  const [radius, setRadius] = useState(keyword?.radius || '')
  const [isFocus, setFocus] = useState(false)
  const { data }: PropsData<Option[]> = useSWR(
    location.name !== '' ? `/hint-locations?t=${location.name}` : null,
    fetchHint,
    {
      fallbackData: [],
      focusThrottleInterval: 5000,
    },
  )

  const onChangeLocation = ({ name, slug }: Option) => {
    setKeyword(slug ?? '', 'location')
    setLocation({ name, slug })
    if (name === '') {
      setKeyword('', 'radius')
      setRadius('')
    }
  }

  const onChangeRadius = (value: string) => {
    setKeyword(value, 'radius')
    setRadius(value)
  }

  return (
    <div className={selectorStyles.parent}>
      <div className="relative mt-1">
        <div className={selectorStyles.inputDiv}>
          <MapPinLine className={selectorStyles.pinMapIcon} />
          <Input
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            name="location"
            autoComplete="off"
            placeholder="Ort + 50km"
            className={selectorStyles.input}
            value={location.name ? location.name : keyword?.location ? unslugifyLocation(keyword.location) : ''}
            onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeLocation({ name: e.target.value, slug: '' })}
          />
        </div>
        <Transition
          show={isFocus}
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className={selectorStyles.optionsBox}>
            <SelectorTableProps location={data} setLocation={onChangeLocation} />
          </div>
        </Transition>
      </div>
      <div className={selectorStyles.button}>
        <RadiusSelector radius={radius ?? keyword.radius} setRadius={onChangeRadius} disabled={location.name === ''} />
      </div>
    </div>
  )
}
