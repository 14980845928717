'use client'
import React, { FC, Fragment, useState } from 'react'
import cx from 'clsx'
import { Minus, Plus } from 'phosphor-react-sc'
import { ImageAsset } from '@/types/site.interface'
import { Transition } from '@headlessui/react'
import ImageElement from '../image'
import { Distribution } from './accordion'

const styles = {
  sectionParent: (distribution: string) =>
    cx('grid container mb-20 gap-y-6 md:gap-x-8', distribution.includes('2') ? 'lg:grid-cols-3' : 'lg:grid-cols-2'),
  itemsColumn: (layout: string, expanded: boolean) =>
    cx('space-y-6 divide-y divide-gray-900/50 border-gray-900/30 border-t-2', {
      'lg:-order-1': layout === 'right-image',
      'lg:col-span-2': expanded,
    }),
  disclosureButton: 'flex w-full items-start justify-between text-left text-text',
  disclosureTitle: 'text-lg font-semibold leading-7 text-text',
  disclosurePanel: 'mt-2 text-base prose-p:m-1 prose-p:leading-7 text-text prose-strong:font-bold',
  disclosureIcon: 'ml-6 flex h-7 items-center',
}

interface AccordionDisclosureProps {
  block: React.ReactNode
  title: string
  onClick?: () => void
  index: number
  indexOpen?: number | null
}

export const AccordionItem: FC<AccordionDisclosureProps> = ({ index, indexOpen, title, block, onClick }) => {
  const isOpen = index === indexOpen
  return (
    <div className="pt-6">
      <Fragment>
        <button className={styles.disclosureButton} onClick={onClick}>
          <span className={styles.disclosureTitle}>{title}</span>
          <span className={styles.disclosureIcon}>{isOpen ? <Minus /> : <Plus />}</span>
        </button>
        <Transition
          show={isOpen}
          className="overflow-hidden"
          enter="transition transition-[max-height] duration-700 ease-in"
          enterFrom="transform max-h-0"
          enterTo="transform max-h-screen"
          leave="transition transition-[max-height] duration-75 ease-out"
          leaveFrom="transform max-h-screen"
          leaveTo="transform max-h-0"
        >
          <div className={styles.disclosurePanel}>{block}</div>
        </Transition>
      </Fragment>
    </div>
  )
}

interface AccordionItem {
  _key: string
  headline: string
  block: React.ReactNode
  image?: ImageAsset
}

interface AccordionClientProps {
  items: AccordionItem[]
  layout: string
  distribution: Distribution
  priorityElement: boolean
}

const imageDistribution = (distribution: Distribution, layout: string) => {
  switch (distribution) {
    case '1:2':
      return layout === 'right-image'
    case '2:1':
      return layout === 'left-image'
    default:
      return false
  }
}

export const ImageFadeIn = ({ priorityElement, image, isActive }) => {
  return (
    <ImageElement
      className={cx('transition-opacity duration-500', isActive ? 'opacity-100' : 'opacity-0')}
      sizes={`
          (min-width: 500px) 50vw, 
          (min-width: 700px) 60vw, 
          (min-width: 960px) 70vw,
          (min-width: 1240px) 80vw,
          (min-width: 1550px) 100vw, 50vw`}
      priority={priorityElement}
      imgClassName={cx('rounded-xl w-full h-full object-cover transition-opacity', !isActive && 'hidden')}
      image={image}
    />
  )
}

export const AccordionClient: FC<AccordionClientProps> = ({
  items,
  priorityElement,
  layout = 'left-image',
  distribution = '2:1',
}) => {
  const [itemOpen, setItemOpen] = useState<number | null>(0)
  const [image, setImage] = useState(items[0].image)
  const expanded = imageDistribution(distribution, layout)

  const handleImage = (index: number, image?: ImageAsset) => {
    if (itemOpen === index) {
      setItemOpen(itemOpen)
      return
    }
    setImage(image)
    setItemOpen(index)
  }

  return (
    <div className={styles.sectionParent(distribution)}>
      <div
        className={cx('relative', {
          'lg:col-span-2': expanded,
        })}
        style={{ aspectRatio: image?.aspectRatio }}
      >
        {items?.map((item, index) => (
          <ImageFadeIn key={index} isActive={itemOpen === index} priorityElement={priorityElement} image={item.image} />
        ))}
      </div>
      <div className={styles.itemsColumn(layout, !expanded && distribution !== '1:1')}>
        {items?.map((item, index) => (
          <AccordionItem
            index={index}
            indexOpen={itemOpen}
            key={item._key}
            title={item.headline}
            block={item.block}
            onClick={() => handleImage(index, item?.image)}
          />
        ))}
      </div>
    </div>
  )
}
