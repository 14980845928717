'use client'

import { FC } from 'react'
import cx from 'clsx'
import useDimensions from '@/hooks/use-dimensions'
import { ApplyButton as IApplyButton, ButtonLink, ImageAsset } from '@/types/site.interface'
import Text from '@ignition/library/components/atoms/text'
import SafeLink from '@ignition/library/src/components/atoms/safe-link'
import { isUndefined } from '@ignition/library/utils/object'
import useWindowScroll from '@react-hook/window-scroll'
import ApplyButton from '../atoms/apply-button'
import Spacer from '../atoms/spacer'
import ImageElement, { aspectRatio as getAspectRatio } from './image'

const styles = {
  parentBgImage:
    'bg-gray-900 pt-10 sm:pt-16 lg:overflow-hidden lg:pt-8 lg:pb-14 bg-cover bg-center object-center relative',
  container: (overlayImage?: boolean, customRatio?: string) =>
    cx(
      'px-4 sm:py-16 sm:px-6 lg:px-8 relative md:absolute bottom-0 right-0 left-0',
      {
        'bg-gradient-to-t from-black/50 to-black/0': overlayImage,
      },
      customRatio === '2:1' || customRatio === '3:1' ? 'py-4 lg:py-10' : 'py-12 lg:py-20',
    ),
  text: (overlayText?: boolean) => cx('max-w-4xl text-white mx-auto text-center', { 'drop-shadow-depth': overlayText }),
  sticky: (show: boolean) => cx('fixed top-0 left-0 w-full z-10 bg-white py-4 shadow-md', !show && 'hidden'),
  stickyContent: 'flex flex-col sm:flex-row mx-auto px-4 max-w-lg sm:px-6 lg:max-w-3xl items-center',
  title: (sticky: boolean) =>
    cx('font-bold tracking-tight', sticky ? 'mb-2 sm:mb-0 text-xl sm:text-2xl' : 'text-3xl sm:text-4xl'),
  summary: 'mt-3 text-xl sm:mt-4 lg:w-2/3 mx-auto',
  button:
    'mt-4 rounded-md py-3 px-4 text-center place-self-center font-medium text-text shadow from-gradient-start to-gradient-end bg-gradient-to-r hover:from-gradient1-end hover:to-gradient1-start ',
}

export interface Overlay {
  image: boolean
  text: boolean
}

interface HeaderImageProps {
  applyButton?: IApplyButton
  image?: ImageAsset
  summary?: string
  title?: string
  overlay?: Overlay
  button?: ButtonLink
}

const StickButton = ({ applyButton, title, parentRef, dim }) => {
  const scrollY = useWindowScroll()
  const offsetTop = parentRef?.current?.offsetTop
  const height = isUndefined(offsetTop) ? 0 : offsetTop + dim.height
  const sticky = scrollY > height

  return (
    <div className={styles.sticky(sticky)}>
      <div className={styles.stickyContent}>
        {title && (
          <Text as="h2" className={styles.title(true)}>
            {title}
          </Text>
        )}
        <Spacer />
        <ApplyButton title={applyButton?.title} />
      </div>
    </div>
  )
}

const HeaderImage: FC<HeaderImageProps> = ({ applyButton, image, summary, title, overlay, button }) => {
  const [ref, dim] = useDimensions<HTMLDivElement>()
  const aspectRatio = getAspectRatio(image?.customRatio)

  return (
    <>
      <div
        ref={ref}
        className={cx('c-header-image', styles.parentBgImage, aspectRatio)}
        style={{ aspectRatio: aspectRatio === 'aspect-auto' ? image?.aspectRatio : '' }}
      >
        <ImageElement image={image} fill priority={true} />
        <div className={styles.container(overlay?.image, image?.customRatio)}>
          <div className={styles.text(overlay?.text)}>
            {title && (
              <Text as="h2" className={styles.title(false)}>
                {title}
              </Text>
            )}
            {summary && (
              <Text as="p" className={styles.summary}>
                {summary}
              </Text>
            )}
            {button && (
              <SafeLink href={button?.href}>
                <span className={styles.button}>{button?.title}</span>
              </SafeLink>
            )}
            {applyButton?.show && <ApplyButton title={applyButton?.title} className="mt-4" />}
          </div>
        </div>
      </div>
      {applyButton?.show && <StickButton applyButton={applyButton} title={title} parentRef={ref} dim={dim} />}
    </>
  )
}

export default HeaderImage
