'use client'

import { useState } from 'react'

const useNextLoading = () => {
  const [loading] = useState(false)

  // TODO: remove since not supported in nextjs 13
  // Think how to migrate
  // useEffect(() => {
  //   router.events.on('routeChangeStart', () => {
  //     setLoading(true)
  //   })

  //   router.events.on('routeChangeComplete', () => {
  //     setLoading(false)
  //   })

  //   router.events.on('routeChangeError', () => {
  //     setLoading(false)
  //   })
  // }, [router])

  return loading
}

export default useNextLoading
