'use client'

import { RefObject, useEffect, useRef, useState } from 'react'

const initialDimensions = {
  width: 0,
  height: 0,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
}

export interface DimensionObject {
  width: number
  height: number
  top: number
  left: number
  right: number
  bottom: number
}

const calculateDimensions = (ref: RefObject<HTMLElement> | null): DimensionObject => {
  if (!ref?.current) {
    return initialDimensions
  }

  const r = ref.current.getBoundingClientRect()
  return {
    width: r.right - r.left,
    height: r.bottom - r.top,
    top: r.top,
    left: r.left,
    right: r.right,
    bottom: r.bottom,
  }
}

export type UseDimensionsHook<T> = [RefObject<T>, DimensionObject]

const useDimensions = <T extends HTMLElement>(): UseDimensionsHook<T> => {
  const ref = useRef<T>(null)
  const [dimensions, setDimensions] = useState({ ...initialDimensions })

  useEffect(() => {
    if (!ref?.current) {
      return
    }

    function handleResize() {
      setDimensions(calculateDimensions(ref))
    }

    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [ref])

  return [ref, dimensions]
}

export default useDimensions
