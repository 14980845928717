import React, { FC } from 'react'
import cx from 'clsx'
import ImageElement from '@/components/elements/image'
import { TeaserProps } from '@/types/site.interface'
import SafeLink from '@ignition/library/components/atoms/safe-link'
import Text from '@ignition/library/components/atoms/text'
import { Button } from '../atoms/button'

const classes = {
  container: 'w-full flex flex-col bg-white justify-between overflow-hidden rounded-lg shadow-lg',
  contentContainer: 'flex flex-1 flex-col bg-white p-6',
  imageContainer: 'flex-shrink-0 w-full aspect-video relative overflow-hidden',
  caption: 'text-sm font-medium text-primary hover:underline',
  title: 'text-xl font-semibold mt-2 text-center text-subheading',
  summary: 'mt-3 text-base text-gray-500',
  category: '',
  buttonContainer: 'flex justify-center items-center p-2',
  continueReadingButton: 'py-2 px-4 rounded-full',
}

const Teaser: FC<TeaserProps> = ({ id, className, caption, title, slug, summary, image, continueReadingButton }) => {
  return (
    <div className={cx(classes.container, className)}>
      <SafeLink id={`grid-${id}`} href={slug}>
        <div className={classes.imageContainer}>
          <ImageElement image={image} fill useFallbackImage sizes="(min-width: 780px) 40vw, 80vw" />
        </div>
        <div className={classes.contentContainer}>
          <Text className={classes.caption}>{caption}</Text>
          <Text className={classes.title}>{title}</Text>
          <Text as="span" className={classes.summary}>
            {summary}
          </Text>
        </div>
      </SafeLink>
      {continueReadingButton && (
        <div className={classes.buttonContainer}>
          <Button href={slug} type="anchor" className={classes.continueReadingButton}>
            {continueReadingButton}
          </Button>
        </div>
      )}
    </div>
  )
}

export default Teaser
