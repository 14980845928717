import { FC } from 'react'
import clsx from 'clsx'

const colors: { [key in LoadingColor]: string } = {
  primary: 'bg-primary',
  secondary: 'bg-secondary',
  white: 'bg-white',
}

const sizes: { [key in Sizes]: string } = {
  xs: 'w-1 h-1 md:w-1.5 md:h-1.5',
  sm: 'w-1.5 h-1.5 md:w-2 md:h-2',
  md: 'h-2.5 w-2.5 md:h-3 md:w-3',
  lg: 'h-3.5 w-3.5 md:h-4 md:w-4',
}

const style = {
  circle: (color: LoadingColor, size: Sizes) =>
    clsx(sizes[size], 'mr-1 rounded-full border-bottom shadow-lg', colors[color]),
}

type LoadingColor = 'primary' | 'secondary' | 'white'
type Sizes = 'xs' | 'sm' | 'md' | 'lg'
interface LoadingProps {
  color?: LoadingColor
  className?: string
  size?: Sizes
}

const Loading: FC<LoadingProps> = ({ color = 'primary', size = 'md', className }) => {
  return (
    <div className={clsx('flex justify-center', className)}>
      <div className={clsx(style.circle(color, size), 'mr-1 animate-bounce ')}></div>
      <div className={clsx(style.circle(color, size), 'mr-1 animate-bounce200')}></div>
      <div className={clsx(style.circle(color, size), 'animate-bounce400')}></div>
    </div>
  )
}

export default Loading
