import { Keyword } from '@/components/elements/job-listing/interface'
import { FallbackImage } from '@/components/elements/job-listing/interface'
import { toCamelCase } from '@ignition/library/utils/string'
import { SearchData, SearchSetting } from './server-jobs-search-props'

const REGEX = /\/jobs\//

export const isSearchPage = (pathname: string): boolean => REGEX.test(pathname)

// will find the keywords in slug Array return: @Keyword
export const keywordHandler = (slug: string[]): Keyword => {
  if (!Array.isArray(slug) || !slug.length) return {}
  const findKeyword = ['search', 'location', 'radius', 'page', 'filter']

  const keywords = findKeyword.reduce((acc, keyword: string) => {
    if (!slug.includes(keyword)) return { ...acc }
    return { ...acc, [keyword]: slug[slug.findIndex((el) => el === keyword) + 1] }
  }, {})

  return { ...keywords }
}

export const processJobsSearchData = (
  searchData: SearchData,
  slug: string,
  settings: SearchSetting,
  keyword: Keyword,
  fallbackImages: FallbackImage[],
) => {
  const { page } = keyword
  const { metaData, data = [] } = searchData ?? {}
  const currentPage = page ? parseInt(page) : 0

  const pagination = {
    baseHref: slug.split('page')[0],
    currentPage,
    totalPages: metaData?.totalPages,
    pagination: {
      style: 'pagination',
    },
  }
  const filters = settings?.filters?.map((filter) => ({
    ...filter,
    sectionId: toCamelCase(filter.name),
  }))
  return {
    ...settings,
    pagination,
    listing: data,
    resultCount: metaData?.total,
    filters,
    activeSearch: keyword?.location?.split(',') ?? [],
    fallbackImages,
  }
}
