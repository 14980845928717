type Locale = string
type Localization = {
  [key: string]: string
}
type StringMapType = Map<Locale, Localization>

const stringMap: StringMapType = new Map()

stringMap.set('de', {
  'applyButton.title': 'Jetzt bewerben',
  'application.header.title': 'Online Bewerbung',
  'application.header.notice': 'Bitte füllen Sie die folgenden Informationen aus',

  'overview.step.upload.title': 'Bewerbungsunterlagen',
  'overview.step.upload.description': 'Hochladen der Bewerbungsunterlagen und Abschluss',
  'overview.step.identity.title': 'Persönliche Angaben',
  'overview.step.identity.description': 'Beschreibung',

  'intro.title': 'Einleitung',
  'intro.minute': 'Minute',
  'intro.minutes': 'Minuten',
  'upload.title': 'Bewerbungsunterlagen hochladen',
  'upload.description':
    'Bitte laden Sie nun Ihre Bewerbungsunterlagen hoch. \n Benötigt werden: Lebenslauf, Referenzen und Qualifikationsnachweise',
  'identity.title': 'Persönliche Angaben',
  'identity.description': 'Bitte geben Sie Ihren Namen und E-Mail-Adresse an.',

  'finish.title': 'Geschafft!',
  'finish.description':
    'Vielen Dank, dass Sie sich die Zeit genommen haben, unsere Onlinebewerbung auszufüllen. Wir sichten Ihre Unterlagen und melden uns in Kürze bei Ihnen.',
  'finish.close': 'Fenster schließen',

  'error.form.email': 'E-Mail wird benötigt.',
  'error.form.name': 'Name wird benötigt.',
  'error.form.phone': 'Telefon wird benötigt',
  'error.form.message': 'Nachricht ist erforderlich',
  'error.form.document': 'Dokument(e) sind erforderlich.',
  'error.form.field.required': 'Bitte füllen Sie dieses Pflichtfeld aus.',
  'error.required': 'Eine Frage wurde nicht ausgefüllt, bitte füllen Sie alle aus.',
  'error.failed': 'Ein Fehler ist aufgetreten, bitte versuchen Sie es später noch einmal.',

  'field.language.none': 'Keine Erfahrung',
  'field.language.beginner': 'Anfänger',
  'field.language.conversational': 'Fortgeschritten',
  'field.language.fluent': 'Fließend',
  'field.language.native': 'Muttersprache',

  'field.rating.comfort.1': 'Überhaupt nicht komfortabel',
  'field.rating.comfort.2': 'Leicht unangenehm',
  'field.rating.comfort.3': 'Neutral',
  'field.rating.comfort.4': 'Leicht komfortabel',
  'field.rating.comfort.5': 'Sehr komfortabel',
  'field.rating.comfort.6': 'Außerordentlich komfortabel',

  'field.rating.performance.1': 'Schlecht',
  'field.rating.performance.2': 'Leicht unterdurchschnittlich',
  'field.rating.performance.3': 'Durchschnittlich',
  'field.rating.performance.4': 'Überdurchschnittlich',
  'field.rating.performance.5': 'Sehr gut',
  'field.rating.performance.6': 'Außergewöhnlich gut',

  'form.field.name': 'Name',
  'form.field.email': 'Email',
  'form.field.message': 'Nachricht',
  'form.field.phone': 'Telefon',

  //Job position Highlight
  'highlight.location': 'Standort',
  'highlight.workingTime': 'Arbeitszeitmodell',
  'highlight.work.fulltime': 'Vollzeit',
  'highlight.work.parttime': 'Teilzeit',
  'highlight.work.contractor': 'Auftragnehmer',
  'highlight.work.temporary': 'Temporär',
  'highlight.work.intern': 'Praktikant',
  'highlight.work.volunteer': 'Freiwilliger',
  'highlight.work.per_diem': 'Tagessatz',
  'highlight.work.other': 'Sonstige',
})

stringMap.set('en', {
  'applyButton.title': 'Apply now',
  'application.header.title': 'Solicitud en línea',
  'application.header.notice': 'Please fill in the following information',

  'overview.step.upload.title': 'Application documents',
  'overview.step.upload.description': 'Upload of application documents and completion',
  'overview.step.identity.title': 'Personal information',
  'overview.step.identity.description': 'Description',

  'intro.title': 'Intro',
  'intro.minute': 'minute',
  'intro.minutes': 'minutes',
  'upload.title': ' Upload application documents',
  'upload.description':
    'Please upload your application documents now. Required are: CV, references, and qualification certificates.',
  'identity.title': 'Personal information',
  'identity.description': 'Please enter name and email address',

  'finish.title': 'Done!',
  'finish.description':
    'Thank you for taking the time to fill out our online application. We will review your documents and get back to you shortly.',
  'finish.close': 'Close window',

  'error.form.email': 'Name is required.',
  'error.form.name': 'Email is required.',
  'error.form.phone': 'Phone is required.',
  'error.form.message': 'Message is required.',
  'error.form.document': 'Document(s) are required.',
  'error.form.field.required': 'Please fill in this required field.',
  'error.required': 'One question was not filled out, please fill out all of them',
  'error.failed': 'Something went wrong, please try again later.',

  'field.language.none': 'No Knowledge',
  'field.language.beginner': 'Beginner',
  'field.language.conversational': 'Conversational',
  'field.language.fluent': 'Fluent',
  'field.language.native': 'Native or Bilingual',

  'field.rating.comfort.1': 'Not at all comfortable',
  'field.rating.comfort.2': 'Slightly uncomfortable',
  'field.rating.comfort.3': 'Neutral',
  'field.rating.comfort.4': 'Slightly comfortable',
  'field.rating.comfort.5': 'Very comfortable',
  'field.rating.comfort.6': 'Exceptionally comfortable',

  'field.rating.performance.1': 'Poorly',
  'field.rating.performance.2': 'Slightly below average',
  'field.rating.performance.3': 'Average',
  'field.rating.performance.4': 'Above average',
  'field.rating.performance.5': 'Very well',
  'field.rating.performance.6': 'Exceptionally well',

  'form.field.name': 'Name',
  'form.field.email': 'Email',
  'form.field.message': 'Message',
  'form.field.phone': 'Phone',

  //Job position Highlight
  'highlight.location': 'Location',
  'highlight.work.time': 'Working Time',
  'highlight.work.fulltime': 'Full-time',
  'highlight.work.parttime': 'Part-Time',
  'highlight.work.contractor': 'Contractor',
  'highlight.work.temporary.': 'Temporal',
  'highlight.work.intern': 'Intern',
  'highlight.work.volunteer': 'Volunteer',
  'highlight.work.per_diem': 'Daily rate',
  'highlight.work.other': 'Others',
})

// Enhance this after adding more languages
const useTranslation = () => {
  const currentLocale = 'de'
  const localizations = stringMap.get(currentLocale)

  const translate: (term: string) => string = (term: string) => localizations?.[term] || term
  return translate
}

export default useTranslation
