import { FC } from 'react'
import clsx from 'clsx'
import useTranslation from '@/translations/resources'
import { Button } from './button'

interface ApplyButtonProps {
  className?: string
  title?: string
}

const ApplyButton: FC<ApplyButtonProps> = ({ className, title }) => {
  const translate = useTranslation()

  return (
    <Button
      scroll
      type="anchor"
      href="#job-survey-section"
      variant="default"
      className={clsx('text-base sm:text-lg', className)}
    >
      {title || translate('applyButton.title')}
    </Button>
  )
}

export default ApplyButton
