'use client'
import React, { FC } from 'react'
import clsx from 'clsx'
import Teaser from '@/components/molecules/teaser'
import { EntryProps } from '@/types/site.interface'
import SliderCarousel from './slider-carousel'

export interface SliderProps {
  entries: EntryProps[]
  layout: 'default' | 'inline'
}

const styles = {
  sliderContainer: 'm-auto mx-auto px-4 max-w-lg sm:px-6 lg:max-w-3xl lg:px-8',
  sliderWrapper: 'w-full relative',
  teaserWidth: (layout: string) =>
    clsx(
      layout === 'inline'
        ? 'aspect-[2/4] min-w-[290px] sm:min-w-[290px]'
        : 'aspect-[3/5] min-w-[270px] sm:min-w-[280px]',
      'flex flex-col relative ',
    ),
}

const EntrySlider: FC<SliderProps> = ({ entries, layout }) => {
  const slides = entries
    .filter((entry) => !!entry)
    .map((item, i) => {
      return (
        <div key={i} className={'w-full h-full'}>
          <div className="flex flex-col mt-12">
            <Teaser
              {...item}
              id={item._id}
              summary={item.summary?.substring(0, 130)}
              category={item.categories?.[0].title || ''}
              className={styles.teaserWidth(layout)}
              continueReadingButton={item.continueReadingButton}
            />
          </div>
        </div>
      )
    })

  return (
    <div className={clsx('c-entry-slider', styles.sliderContainer)}>
      <div className={styles.sliderWrapper}>
        <SliderCarousel layout={layout} slides={slides} inline />
      </div>
    </div>
  )
}

export default EntrySlider
