'use client'

import { FC, Fragment, useRef } from 'react'
import clsx from 'clsx'
import dynamic from 'next/dynamic'
import useIntersectionObserver from '@react-hook/intersection-observer'

interface SliderCarouselProps {
  inline?: boolean
  layout?: 'default' | 'inline'
  slides: JSX.Element[]
}

const Carousel = dynamic(() => import('@ignition/library/components/molecules/carousel'))
const SlideList = dynamic(() =>
  import('@ignition/library/components/molecules/carousel/elements/slide-list').then((mod) => mod.SlideList),
)

const SliderCarousel: FC<SliderCarouselProps> = ({ inline, slides }) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const lockRef = useRef(false)
  const { isIntersecting } = useIntersectionObserver(containerRef)
  if (!slides) return null

  // render Carousel on scroll
  if (isIntersecting && !lockRef.current) {
    lockRef.current = true
  }

  return (
    <div className="overflow-hidden" ref={containerRef}>
      {lockRef.current ? (
        <Carousel
          autoplay={false}
          loop={false}
          watchDrag
          slidesToScroll={'auto'}
          className={clsx(inline && 'sm:overflow-hidden relative')}
        >
          <SlideList
            slides={slides}
            hideOverflow={false}
            renderSlide={(slide, i) => <Fragment key={i}>{slide}</Fragment>}
            containerClassName="gap-4"
          />
        </Carousel>
      ) : (
        <div className="h-[15vh]" />
      )}
    </div>
  )
}

export default SliderCarousel
