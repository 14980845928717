'use client'

import { FC } from 'react'
import cx from 'clsx'
import { FAQPageJsonLd } from 'next-seo'
import { Minus, Plus } from 'phosphor-react-sc'
import { Blocks, Section } from '@/types/site.interface'
import { ButtonLink } from '@/types/site.interface'
import { Disclosure } from '@headlessui/react'
import { Button } from '../atoms/button'
import BlockContent from '../blocks/block-content'
import SectionHeading from '../molecules/section/section-heading'

const styles = {
  sectionParent: (hasSection: boolean) => cx('c-content-faq grid container mb-20', { 'lg:grid-cols-2': hasSection }),
  headingContainer: 'flex flex-col items-center mt-20 text-left lg:prose-headings:text-left lg:prose-p:text-left',
  faqParentDiv: (hasSection: boolean) => cx('mx-auto max-w-7xl px-4 w-full', { 'lg:w-9/12': !hasSection }),
  faqContainer: 'mx-auto max-h-4xl divide-y divide-gray-900/10',
  dlElement: 'mt-10 space-y-6 divide-y divide-gray-900/10',
  disclosureButton: 'flex w-full items-start justify-between text-left text-gray-900',
  disclosureQuestion: 'text-lg font-semibold leading-7',
  disclosureAnswer: 'text-base prose-p:m-1 prose-p:leading-7 text-gray-600',
  disclosureIcon: 'ml-6 flex h-7 items-center',
}

interface FAQ {
  _key: string
  question: string
  answer: Blocks
}

interface JsonLdFAQ {
  questionName: string
  acceptedAnswerText: string
}

interface ContentFAQProps {
  faqs: FAQ[]
  section: Section
  button?: ButtonLink
  jsonLdFAQ: JsonLdFAQ[]
}

const ContentFAQ: FC<ContentFAQProps> = ({ faqs, section, button, jsonLdFAQ = [] }) => {
  const hasSection = typeof section?.heading !== 'undefined'
  const { title = '', variant, linkType, href, entry } = button ?? {}
  const slug = linkType === 'entry' ? entry?.slug : href
  return (
    <section className={styles.sectionParent(hasSection)}>
      <FAQPageJsonLd mainEntity={jsonLdFAQ} />
      <div className={styles.headingContainer}>
        <SectionHeading {...section} />
        {title && (
          <Button type="anchor" variant={variant} href={slug}>
            {title}
          </Button>
        )}
      </div>
      <div className={styles.faqParentDiv(hasSection)}>
        <div className={styles.faqContainer}>
          <dl className={styles.dlElement}>
            {faqs?.map((faq) => (
              <Disclosure as="div" key={faq?._key} className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className={styles.disclosureButton}>
                        <span className={styles.disclosureQuestion}>{faq?.question}</span>
                        <span className={styles.disclosureIcon}>{open ? <Minus /> : <Plus />}</span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2">
                      <div>
                        <BlockContent className={styles.disclosureAnswer} blocks={faq?.answer} />
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </section>
  )
}
export default ContentFAQ
