import { FormSubmitStatus } from '@/types/site.interface'
import logger from '@ignition/library/lib/logger'

const handleSendGrid = async (formData: FormData): Promise<void> => {
  const res = await fetch('/api/send-email', {
    method: 'POST',
    body: formData,
  })
  try {
    const response = await res.json()
    if (!response || response.error) {
      logger.error(response?.error)
      throw Error('Fetch submission went wrong')
    }
    return response.message
  } catch (err) {
    logger.error(err)
    throw Error(err)
  }
}

export const onSubmit = async (formData: FormData): Promise<FormSubmitStatus> => {
  return await handleSendGrid(formData)
    .then((res) => {
      logger.info('Form submission successfully created', res)
      return 'success' as const
    })
    .catch((err) => {
      logger.error('Form submission went wrong', err)
      return 'error' as const
    })
}
