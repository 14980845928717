'use client'

import { FC } from 'react'
import { useCarouselContext } from '../constants'

interface CarouselPrevButtonProps {
  onClick: () => void
  disabled: boolean
}

interface PrevButtonProps {
  children: (props: CarouselPrevButtonProps) => JSX.Element
}

export const PrevButton: FC<PrevButtonProps> = ({ children }) => {
  const { scrollPrev, canScrollPrev, scrollSnaps } = useCarouselContext()
  if (scrollSnaps.length <= 1) {
    return null
  }
  return children({ onClick: () => scrollPrev(), disabled: !canScrollPrev })
}
